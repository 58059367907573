import {
  MM_TO_M,
  HOLDING,
  BLOCK_ROOT,
  DECAL_INSTRUMENT_PLANE,
  HOLE_PLANE_ASSET_ID,
  INSTRUMENT_PLACEHOLDER_ASSET,
  INSTRUMENT_SHADOW_MAP,
} from '../../constants';
import {
  getWorldTranslation,
  getAssetInstance,
  getConfiguratorInstance,
  getNodeBoundingBox,
  getLidDecalAspectRatio,
  autoRetry,
} from './threekit';

export const createBlockInstance = async (threekitApi, blockData) => {
  const { assetId, colorAssetId, productLabel, silhouette } = blockData;

  await setBlockInstanceConfiguration(threekitApi, {
    Block: { assetId },
    'Block Color': { assetId: colorAssetId },
  });
  const modelInstanceId = await getBlockInstanceId(threekitApi);
  const from = { id: modelInstanceId };
  threekitApi.scene.filterNodes({ name: 'Hole_*', from }).map(async (id) => {
    threekitApi.scene.set(
      { id, plug: 'Properties', property: 'visible' },
      false
    );
    threekitApi.scene.addNode(
      {
        name: 'Decal_Hole_Plane',
        type: 'Model',
        plugs: {
          Null: [{ type: 'Model', asset: { assetId: HOLE_PLANE_ASSET_ID } }],
        },
      },
      id
    );
  });

  const blockHoleData = await fetchBlockHoleData(threekitApi, blockData);
  const planes = Object.values(blockHoleData.movingPlane);
  const holdingHeight =
    planes.reduce((sum, plane) => sum + plane.constant, 0) / planes.length;
  threekitApi.scene.set(
    { name: HOLDING.root, plug: 'Transform', property: 'translation' },
    {
      x: 0,
      y: holdingHeight,
      z: blockData.depth * MM_TO_M,
    }
  );
  return updatePrefetchBlockHoleId(threekitApi, blockHoleData, modelInstanceId);
};

export const getBlockInstanceId = async (threekitApi) => {
  const blockModelId = threekitApi.scene.findNode({
    name: BLOCK_ROOT,
    from: threekitApi.instanceId,//changed from assetID to imnstance ID
  });
  return getAssetInstance(threekitApi, {
    id: blockModelId,
    plug: 'Null',
    property: 'asset',
  });
};

export const setBlockInstanceConfiguration = async (
  threekitApi,
  configuration
) => {
  const configurator =
    threekitApi?.player?.configurator || threekitApi?.player?.assetConfigurator;
  await configurator?.setConfiguration(configuration);
};

export const fetchBlockHoleData = (() => {
  const cachedBlockData = {};
  return async (threekitApi, blockData) => {
    const { assetId, productLabel } = blockData;
    if (!cachedBlockData[assetId]) {
      await threekitApi.scene.fetch(assetId);
      try {
        const holeData = getBlockHolesData(threekitApi, assetId);
        const { holeDistance, edgeDistance } = getBlockHolesDistance(
          holeData,
          blockData
        );
        const { movingPlane, movingBoundary } = getMovingPlaneAndBoundary(
          threekitApi,
          holeData,
          blockData
        );
        cachedBlockData[assetId] = {
          holeData,
          holeDistance,
          edgeDistance,
          movingPlane,
          movingBoundary,
        };
      } catch (e) {
        throw new Error(`Block ${productLabel} (${assetId}) ${e}`);
      }
    }
    return cachedBlockData[assetId];
  };
})();

function getBlockHolesData(threekitApi, blockId) {
  const holeIds = threekitApi.scene.filterNodes({
    from: blockId,
    name: 'Hole_*',
  });
  if (!holeIds.length) throw `missing hole reference node!`;
  const maxIdx = {};
  const holesData = holeIds.reduce((holesData, id) => {
    const name = threekitApi.scene.get({ id, property: 'name' });
    const translation = getWorldTranslation(threekitApi, id);
    const [_, shank, idx] = name.split('_');
    if (!shank) throw `has invalid nodename ${name}: missing shank type!`;
    if (Number.isNaN(Number(idx)))
      throw `has invalid nodename ${name}: invalid idx!`;
    if (!holesData[shank]) {
      holesData[shank] = {};
      maxIdx[shank] = 0;
    }

    const holeData = { id, name, shank, idx, translation };

    if (holesData[shank][idx]) throw `has duplicate hole name ${name}!`;
    maxIdx[shank] = Math.max(maxIdx[shank], Number(idx));
    holesData[shank][idx] = holeData;
    return holesData;
  }, {});

  Object.keys(holesData).forEach((shank) => {
    if (maxIdx[shank] > Object.keys(holesData[shank]).length)
      throw `has invalid hole idx; idx of shank type ${shank} can not be larger than the number of holes!`;
  });
  return holesData;
}

function getBlockHolesDistance(holesData, blockData) {
  const { innerWidth, holesCount } = blockData;
  if (!holesCount) throw `missing holesCount property in spreadsheet.`;
  const count = collapseHolesCount(holesCount);
  const shanks = Object.keys(count);
  if (shanks.length !== Object.keys(holesData).length)
    throw `holesCount data missing match with the model setup!`;
  shanks.forEach((shank) => {
    if (
      !holesData[shank] ||
      count[shank] !== Object.keys(holesData[shank]).length
    )
      throw `holesCount data missing match with the model setup!`;
  });

  if (!innerWidth) throw `missing innerWidth property in spreadsheet.`;
  const halfWidth = (innerWidth / 2) * MM_TO_M;
  const shankTypes = Object.keys(holesData);
  const holeDistance = {};
  const edgeDistance = {};
  for (let shank1 = 0; shank1 < shankTypes.length; ++shank1) {
    for (let shank2 = shank1; shank2 < shankTypes.length; ++shank2) {
      const shankName1 = shankTypes[shank1];
      const shankName2 = shankTypes[shank2];
      const holesType1Idx = Object.keys(holesData[shankName1]);
      const holesType2Idx = Object.keys(holesData[shankName2]);
      for (let hole1 = 0; hole1 < holesType1Idx.length; ++hole1) {
        const holeIdx1 = holesType1Idx[hole1];
        const key1 = `${shankName1}_${holeIdx1}`;
        const translation1 = holesData[shankName1][holeIdx1].translation;
        if (!holeDistance[key1]) holeDistance[key1] = {};
        if (Math.abs(translation1.x) > halfWidth)
          throw `innerWidth property does not match the scene setup! Holes position outside block inner area.`;
        edgeDistance[key1] = Math.min(
          Math.abs(translation1.x - halfWidth),
          Math.abs(translation1.x + halfWidth)
        );

        let hole2 = shank1 === shank2 ? hole1 + 1 : 0;
        for (hole2; hole2 < holesType2Idx.length; ++hole2) {
          const holeIdx2 = holesType2Idx[hole2];
          const key2 = `${shankName2}_${holeIdx2}`;
          const translation2 = holesData[shankName2][holeIdx2].translation;
          const distance = translation1.distanceTo(translation2);

          if (!holeDistance[key2]) holeDistance[key2] = {};
          holeDistance[key1][key2] = holeDistance[key2][key1] = distance;
        }
      }
    }
  }

  return { holeDistance, edgeDistance };
}

function getMovingPlaneAndBoundary(threekitApi, holesData, blockData) {
  const { THREE } = threekitApi;

  const movingPlane = Object.keys(holesData).reduce((plane, shankType) => {
    const holes = Object.values(holesData[shankType]);
    const totalY = holes.reduce(
      (sum, holeData) => sum + holeData.translation.y,
      0
    );
    plane[shankType] = new THREE.Plane(
      new THREE.Vector3(0, -1, 0),
      totalY / holes.length
    );
    return plane;
  }, {});

  const { depth, innerWidth } = blockData;
  const halfDepth = (depth / 2) * MM_TO_M;
  const halfWidth = (innerWidth / 2) * MM_TO_M;
  const movingBoundary = { x: halfWidth, z: halfDepth };
  return { movingPlane, movingBoundary };
}

function updatePrefetchBlockHoleId(threekitApi, blockData, instanceId) {
  const newHoleData = { ...blockData.holeData };
  Object.keys(newHoleData).forEach((shank) => {
    newHoleData[shank] = { ...newHoleData[shank] };
    Object.keys(newHoleData[shank]).forEach((idx) => {
      const id = threekitApi.scene.findNode({
        from: instanceId,
        name: newHoleData[shank][idx].name,
      });
      newHoleData[shank][idx] = {
        ...newHoleData[shank][idx],
        id,
      };
    });
  });
  return { ...blockData, holeData: newHoleData };
}

export const createInstrumentInstance = async (
  threekitApi,
  instrumentData,
  options = {}
) => {
  const {
    holeData,
    silhouette,
    etchingLabelTextLimit,
    etchingLabelFont,
    silhouettePosition,
    silhouetteSize,
    siliconInsert,
    instrumentHeightOffset,
  } = options;
  const { shank, assetId, etchingLabel, silhouetteAssetId } = instrumentData;

  let translation,
    referenceAssetId,
    configuration,
    validEtchingLabel,
    holeTranslation;
  if (holeData) {
    // for configurator mode
    holeTranslation = holeData.translation;
    translation = getInstrumentTranslation(
      holeTranslation,
      instrumentHeightOffset
    );
    referenceAssetId = INSTRUMENT_PLACEHOLDER_ASSET;
    validEtchingLabel = validEtchingLabelByLimit(
      etchingLabel,
      etchingLabelTextLimit
    );
    configuration = {
      Instrument: { assetId },
      'Etching Label': parseEtchingLabel(validEtchingLabel, ';'),
      Shadow: INSTRUMENT_SHADOW_MAP[shank] || 'Small',
      Silhouette: silhouette ? { assetId: silhouetteAssetId } : undefined,
      'Silhouette Position': silhouettePosition,
      'Silhouette Size': getInstrumentDecalPxSize(silhouetteSize),
      'Font Size': getInstrumentDecalPxSize(etchingLabelFont),
      'Silicon Insert': siliconInsert,
    };
  } else {
    // for view mode
    referenceAssetId = assetId;
  }
  const objects = threekitApi.scene.findNode({
    from: threekitApi.instanceId, //changed from assetID
    type: 'Objects',
  });
  const modelId = await threekitApi.scene.addNode(
    {
      name: 'Instrument',
      type: 'Model',
      plugs: {
        Null: [
          {
            type: 'Model',
            asset: { assetId: referenceAssetId, configuration },
          },
        ],
        Transform: [{ type: 'Transform', translation }],
      },
    },
    objects
  );
  const instanceId = await getAssetInstance(threekitApi, {
    id: modelId,
    plug: 'Null',
    property: 'asset',
  });
  if (!configuration) {
    const instruemntPartsAssetId = getInstrumentPartsAssetId(
      threekitApi,
      instanceId
    );
    applyMaterialToInsturmentParts(
      threekitApi,
      instruemntPartsAssetId,
      instrumentData
    );
    return { modelId };
  }
  const configurator = await getConfiguratorInstance(threekitApi, {
    id: modelId,
    plug: 'Null',
    property: 'asset',
  });

  const decalPlaneId = threekitApi.scene.findNode({
    from: instanceId,
    name: DECAL_INSTRUMENT_PLANE,
  });
  setInsturmentDecalPosition(
    threekitApi,
    decalPlaneId,
    holeData,
    instrumentHeightOffset
  );
  initialInstrumentMaterial(threekitApi, instanceId, instrumentData);

  return {
    modelId,
    configurator,
    decalPlaneId,
    etchingLabel: validEtchingLabel,
  };
};

export const setInsturmentDecalPosition = (
  threekitApi,
  decalPlaneId,
  holeData,
  optionalOffset
) => {
  const translationY = holeData.translation.y - optionalOffset / 1000 || 0;
  threekitApi.scene.set(
    { id: decalPlaneId, plug: 'Transform', property: 'translation' },
    { x: 0, y: translationY, z: 0 }
  );
};

const applyMaterialToInsturmentParts = (() => {
  const cachedMaterial = {};
  return async (threekitApi, instruemntPartsAssetId, instrumentData) => {
    for (let { id, part } of instruemntPartsAssetId) {
      const materialAssetId = instrumentData[`${part}MaterialAssetId`];
      if (!materialAssetId) {
        console.warn(
          `Instrument ${
            instrumentData.productNumber
          } missing material assetId for part ${part}!`
        );
        continue;
      }
      const materialId = cachedMaterial[materialAssetId];
      if (materialId)
        threekitApi.scene.set(
          { id, plug: 'Material', property: 'reference' },
          materialId
        );
      else
        cachedMaterial[materialAssetId] = await applyMaterialAsset(
          threekitApi,
          id,
          materialAssetId
        );
    }
  };
})();

export const validInstrumentBasedOnLayout = (
  blockState,
  instrumentState,
  instruments
) => {
  const { holeData } = blockState;
  const occipiedHole = getOccipiedHole(holeData, instrumentState);

  const inputAsArray = Array.isArray(instruments);
  const result = (inputAsArray ? instruments : [instruments])
    .map((instrument) =>
      findAvailableHole(blockState, instrumentState, instrument, {
        findFirst: true,
        occipiedHole,
      })
    )
    .map((result) => ({
      holeIdx: result && result.idx,
      holeShank: result && result.shank,
    }));
  return inputAsArray ? result : result[0];
};

export function findAvailableHole(
  blockState,
  instrumentState,
  instrumentData,
  options = {}
) {
  const {
    headSize,
    shank,
    name,
    modelId,
    holeIdx: modelHoleIdx,
    holeShank: modelHoleShank,
  } = instrumentData;
  const {
    findFirst,
    occipiedHole: optionalOccipied,
    allowSwap,
    holding,
  } = options;
  if (!shank) return console.error(`Instrument ${name} missing data shank!`);

  if (!headSize)
    return console.error(`Instrument ${name} missing data headSize!`);

  if (!modelId && allowSwap)
    throw `You can only swap between existing instruments!`;
  const holeData = { ...blockState.holeData, [HOLDING.shankName]: holding };
  const filteredInstrumentState = { ...instrumentState };
  if (modelId) delete filteredInstrumentState[modelId];
  const occipiedHole =
    optionalOccipied ||
    getOccipiedHole(holeData, filteredInstrumentState, holding);

  const compatibleHoles = getCompatibleHoles(occipiedHole, shank);
  const validHole = [];
  const compatibleShank = Object.keys(compatibleHoles);
  for (let idx = 0; idx < compatibleShank.length; ++idx) {
    const targetHoleShank = compatibleShank[idx];
    const occupied = compatibleHoles[targetHoleShank];
    for (
      let targetHoleIdx = 1;
      targetHoleIdx < occupied.length;
      ++targetHoleIdx
    ) {
      let localInstrumnentState = filteredInstrumentState;

      if (occupied[targetHoleIdx]) {
        // current hole has been occupied and no swap allowed, continue to check next hole
        if (!allowSwap) continue;

        // check if the occupied instrument can swap to the dragging instrument's original hole
        // continue to check next hole if the swapping if not allowed
        const swapModelId = occupied[targetHoleIdx];
        localInstrumnentState = { ...filteredInstrumentState };
        delete localInstrumnentState[swapModelId];
        if (
          !getHoleAvailability(
            blockState,
            localInstrumnentState,
            instrumentState[swapModelId],
            holeData[modelHoleShank][modelHoleIdx]
          )
        )
          continue;

        // adjust localInstrumentState so that the swapped instrument are locate at dragging instrument original hole
        localInstrumnentState[swapModelId] = {
          ...filteredInstrumentState[swapModelId],
          holeIdx: modelHoleIdx,
          holeShank: modelHoleShank,
        };
      }

      // check if the instrument can be placed at the target hole
      if (
        !getHoleAvailability(
          blockState,
          localInstrumnentState,
          instrumentData,
          holeData[targetHoleShank][targetHoleIdx]
        )
      )
        continue;

      const targetHole = holeData[targetHoleShank][targetHoleIdx];
      if (findFirst) return targetHole;
      else validHole.push(targetHole);
    }
  }

  return findFirst ? undefined : validHole;
}

function getCompatibleHoles(occipiedHole, shank) {
  return Object.keys(occipiedHole).reduce((holes, holeShank) => {
    if (
      holeShank === shank ||
      holeShank === HOLDING.shankName ||
      holeShank.split('/').indexOf(shank) >= 0
    )
      return Object.assign(holes, { [holeShank]: occipiedHole[holeShank] });
    return holes;
  }, {});
}

export function getOccipiedHole(
  holeData,
  instrumentState,
  optionalHoldingData
) {
  const occipiedHole = Object.entries(holeData).reduce(
    (occipied, [shank, data]) =>
      Object.assign(occipied, {
        [shank]: new Array(Object.keys(data).length + 1),
      }),
    {}
  );

  optionalHoldingData &&
    (occipiedHole[HOLDING.shankName] = new Array(
      Object.keys(optionalHoldingData).length + 1
    ));

  Object.values(instrumentState).forEach(
    ({ holeShank, holeIdx, modelId, assetId }) =>
      occipiedHole[holeShank] &&
      (occipiedHole[holeShank][holeIdx] = modelId || assetId)
  );
  return occipiedHole;
}

function getHoleAvailability(
  blockState,
  instrumentState,
  instrumentData,
  holeData
) {
  const { idx: holeIdx, shank: holeShank } = holeData;
  if (holeShank === HOLDING.shankName) return true;
  const { headSize, shank: instrumentShank } = instrumentData;
  if (holeShank.split('/').indexOf(instrumentShank) < 0) return false;

  const { holeDistance, edgeDistance } = blockState;
  const headSizeInM = (headSize * MM_TO_M) / 2;

  const existInstruments = Object.values(instrumentState);
  const targetHoleKey = `${holeShank}_${holeIdx}`;

  if (edgeDistance[targetHoleKey] < headSizeInM) return false;

  for (let existInstrument of existInstruments) {
    const {
      headSize: existHeadSize,
      holeIdx: existHoleIdx,
      holeShank: existHoleShank,
    } = existInstrument;
    if (existHoleShank === HOLDING.shankName) continue;
    const existHeadSizeInM = (existHeadSize * MM_TO_M) / 2;
    const existHoleKey = `${existHoleShank}_${existHoleIdx}`;
    if (
      existHeadSizeInM + headSizeInM >
      holeDistance[targetHoleKey][existHoleKey]
    )
      return false;
  }

  return true;
}

export const validBlockBasedOnInstruments = (blockState, sortedInstruments) => {
  const instruments = Object.keys(sortedInstruments).reduce(
    (instrumentArray, holeShank) => {
      if (holeShank === HOLDING.shankName) return instrumentArray;
      return instrumentArray.concat(sortedInstruments[holeShank]);
    },
    []
  );
  if (!instruments.length) return {};

  const occipiedHole = getOccipiedHole(blockState.holeData, {});

  // first check: check if the instrument number is more than block hole count
  const blockHolesCount = collapseHolesCount(blockState.blockData.holesCount);
  const totalHoleCount = Object.values(blockHolesCount).reduce(
    (total, num) => total + num,
    0
  );
  if (instruments.length > totalHoleCount) return;

  // final check: loop to get the new instrument position with new block data
  const newInstruments = {};
  for (let instrument of instruments) {
    const availableHole = findAvailableHole(
      blockState,
      newInstruments,
      instrument,
      { findFirst: true, occipiedHole }
    );
    if (!availableHole) return;
    const { idx: holeIdx, shank: holeShank } = availableHole;
    newInstruments[instrument.modelId] = {
      ...instrument,
      holeIdx,
      holeShank,
    };
    occipiedHole[holeShank][holeIdx] = instrument.modelId;
  }

  return Object.values(newInstruments);
};

export const sortInstrumentsByProperty = (instruments, property, sortFunc) => {
  const instrumentsAsArray = Object.values(instruments);
  if (!instrumentsAsArray.length) return;

  const sortedInstruments = Object.values(instruments).reduce(
    (instrumentsByProperty, instrument) => {
      if (!instrument.hasOwnProperty(property))
        throw new Error(
          `sortInstrumentsByProperty error! Invalid property ${property}!`
        );
      const propertyValue = instrument[property];
      if (!instrumentsByProperty[propertyValue])
        instrumentsByProperty[propertyValue] = [];
      instrumentsByProperty[propertyValue].push(instrument);
      return instrumentsByProperty;
    },
    {}
  );
  if (typeof sortFunc === 'function')
    Object.values(sortedInstruments).forEach((instrumentByProperty) =>
      instrumentByProperty.sort(sortFunc)
    );
  return sortedInstruments;
};

export const findClosestHole = (translation, holesData) => {
  if (!holesData.length) return;
  let minIdx = 0;
  let minDistanceSquare = xzSquareDistance(
    translation,
    holesData[0].translation
  );

  for (let idx = 1; idx < holesData.length; ++idx) {
    const distanceSquare = xzSquareDistance(
      translation,
      holesData[idx].translation
    );
    if (distanceSquare < minDistanceSquare) {
      minDistanceSquare = distanceSquare;
      minIdx = idx;
    }
  }
  return { ...holesData[minIdx], distance: Math.sqrt(minDistanceSquare) };
};

function xzSquareDistance(translation1, translation2) {
  const dx = translation1.x - translation2.x;
  const dz = translation1.z - translation2.z;
  return dx * dx + dz * dz;
}

export const collapseHolesCount = (holesCount) =>
  holesCount.reduce((count, [shank, num]) => {
    if (!count[shank]) count[shank] = 0;
    count[shank] += Number(num);
    return count;
  }, {});

export const highlightInstrument = async (threekitApi, modelId) => {
  if (!modelId) return threekitApi.selectionSet.set([]);
  const instanceId = await getAssetInstance(threekitApi, {
    id: modelId,
    plug: 'Null',
    property: 'asset',
  });
  const instrumentId = threekitApi.scene.findNode({
    from: instanceId,
    name: 'Instrument',
  });
  threekitApi.selectionSet.setStyle({
    outlineColor: '#000130',
    outlineThickness: 0.1,
  });
  threekitApi.selectionSet.set([instrumentId]);
};

export const highlightOverlay = (threekitApi, overlayModelIds) => {
  threekitApi.selectionSet.setStyle({ outlineColor: '#ff0000' });
  threekitApi.selectionSet.set(overlayModelIds);
};

export const getInstrumentTranslation = (translation, optionalOffset) => {
  if (!translation) return;
  const newTranslation = translation.clone
    ? translation.clone()
    : { ...translation };
  newTranslation.y = optionalOffset / 1000 || 0;
  return newTranslation;
};

const holeHighligher = (state) => (threekitApi, availableHoles) => {
  threekitApi.scene.set(
    { name: HOLDING.root, plug: 'Properties', property: 'visible' },
    state
  );
  availableHoles.forEach(({ id }) =>
    threekitApi.scene.set(
      { id, plug: 'Properties', property: 'visible' },
      state
    )
  );
};

export const highlightAvailableHoles = holeHighligher(true);

export const cancelHighlightAvailableHoles = holeHighligher(false);

export const getModelXRotation = (threekitApi, rotation) => {
  const rotationEuler = new threekitApi.THREE.Euler()
    .setFromVector3(rotation.multiplyScalar(Math.PI / 180), 'ZYX')
    .reorder('XYZ');
  return rotationEuler.toVector3().x * (180 / Math.PI);
};

export const updateInstrument = (configurator, configuration) => {
  if (configuration['Etching Label'])
    configuration['Etching Label'] = parseEtchingLabel(
      configuration['Etching Label'],
      ';'
    );
  configurator.setConfiguration(configuration);
};
export const parseEtchingLabel = (etchingLabel, optionalSeparator) => {
  let result = etchingLabel.replace(/[\r\n]/g, '<br>');
  if (optionalSeparator) result = result.split(optionalSeparator).join('<br>');
  return result;
};
export const validEtchingLabelByLimit = (etchingLabel = '', limits) => {
  return etchingLabel
    .split(';')
    .slice(0, limits.length)
    .map((label, idx) => label.substring(0, Number(limits[idx])))
    .join(';');
};

const applyMaterialAsset = async (threekitApi, meshId, assetId) => {
  threekitApi.scene.set(
    { id: meshId, plug: 'Material', property: 'reference' },
    null
  );
  threekitApi.scene.set(
    { id: meshId, plug: 'Material', property: 'asset' },
    { assetId }
  );
  const materialAssetId = await autoRetry(getAssetInstance, [
    threekitApi,
    { id: meshId, plug: 'Material', property: 'asset' },
  ]);
  return materialAssetId;
};

export const initHoldingHolesData = async (threekitApi) => {
  if (!HOLDING.assetId) return;
  const holdingNullId = await threekitApi.scene.addNode(
    {
      name: HOLDING.root,
      type: 'Model',
      plugs: { Null: [{ type: 'Model', asset: { assetId: HOLDING.assetId } }] },
    },
    threekitApi.assetId
  );
  const holdingRootId = await getAssetInstance(threekitApi, {
    id: holdingNullId,
    plug: 'Null',
    property: 'asset',
  });
  threekitApi.scene.set(
    { id: holdingNullId, plug: 'Properties', property: 'visible' },
    false
  );
  try {
    const holdingData = getBlockHolesData(threekitApi, holdingRootId);
    if (!holdingData[HOLDING.shankName])
      throw `missing holding shank ${HOLDING.shankName}!`;
    return holdingData[HOLDING.shankName];
  } catch (e) {
    throw new Error(`Holding Area asset (${HOLDING.assetId}) ${e}`);
  }
};

export const setInstrumentPosition = (
  threekitApi,
  instrumentData,
  holeData,
  options = {}
) => {
  const { modelId, decalPlaneId } = instrumentData;
  const { translation, shank } = holeData;
  const { offset } = options;
  threekitApi.scene.set(
    { id: modelId, plug: 'Transform', property: 'translation' },
    getInstrumentTranslation(translation, offset)
  );
  const decalPlaneVisibility = threekitApi.scene.get({
    id: decalPlaneId,
    plug: 'Properties',
    property: 'visible',
  });
  if (shank === HOLDING.shankName) {
    decalPlaneVisibility &&
      threekitApi.scene.set(
        { id: decalPlaneId, plug: 'Properties', property: 'visible' },
        false
      );
  } else {
    !decalPlaneVisibility &&
      threekitApi.scene.set(
        { id: decalPlaneId, plug: 'Properties', property: 'visible' },
        true
      );
  }
};

export const hitInstrument = (hit, instruments) => {
  if (!hit) return false;
  const { hierarchy } = hit;
  for (let idx = 0; idx < hierarchy.length; ++idx) {
    const { nodeId } = hierarchy[idx];
    if (instruments[nodeId])
      return (
        hierarchy[idx + 2] &&
        hierarchy[idx + 2].name === 'Instrument' &&
        instruments[nodeId]
      );
  }
};

export const hitBlock = (hits, instruments) => {
  function hitOnBlock(hit) {
    // return ture if hit the block asset AND block main mesh
    // return false if hit the block asset AND not the block main mesh
    // return undefined if not hit the block asset
    const { hierarchy } = hit;
    for (let nodeHit of hierarchy) {
      if (nodeHit.name === BLOCK_ROOT)
        return hierarchy[hierarchy.length - 1].name !== 'BlockShadow';
    }
  }
  for (const hit of hits) {
    const hitStatus = hitOnBlock(hit);
    if (hitStatus !== undefined) return hitStatus;
    if (hitInstrument(hit, instruments)) return false;
  }
  return false;
};

export const getInstrumentMovingPlane = (blockData, instrument) => {
  const { movingPlane } = blockData;
  const { shank } = instrument;
  if (movingPlane[shank]) return movingPlane[shank];
  const compatibleShank = Object.keys(movingPlane).find(
    (holeShank) => holeShank.split('/').indexOf(shank) >= 0
  );
  return movingPlane[compatibleShank];
};

let annotationElements = {};

function setAnnotation(annotation, el = document.createElement('span')) {
  el.setAttribute(
    'style',
    'position: absolute; width: 24px; height: 24px; transform: translate(-50%, -50%); left: ' +
      annotation.left +
      'px; top: ' +
      annotation.top +
      'px; display: ' +
      (annotation.visible ? 'block;' : 'none')
  );
  el.innerHTML =
    '<svg fill="#1D46B0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z"/></svg>';

  return el;
}

export function onAnnotationChange(annotations, parentEl) {
  if (!parentEl) {
    return;
  }

  for (var i = 0; i < annotations.length; i++) {
    const annotation = annotations[i];

    let element = annotationElements[annotation.id];
    const appendToBody = !element;

    element = setAnnotation(annotation, element);

    if (!appendToBody) {
      continue;
    }

    parentEl.appendChild(element);
    annotationElements[annotation.id] = element;
  }
}

export async function frameScene(threekitApi, optionalIds) {
  if (optionalIds) return threekitApi.camera.frameBoundingSphere(optionalIds);

  const blockModelId = threekitApi.scene.findNode({ name: BLOCK_ROOT });
  const blockInstance = await getAssetInstance(threekitApi, {
    id: blockModelId,
    plug: 'Null',
    property: 'asset',
  });
  const bodyId = threekitApi.scene.findNode({
    name: 'Body',
    from: { id: blockInstance },
  });
  const bodyBB = await getNodeBoundingBox(threekitApi, bodyId);
  const blockBB = await getNodeBoundingBox(threekitApi, blockInstance);
  const scale = {
    x: bodyBB.max.x - bodyBB.min.x,
    y: blockBB.max.y - blockBB.min.y,
    z: blockBB.max.z - blockBB.min.z,
  };
  const frameBoxId = threekitApi.scene.findNode({ name: 'Box' });
  threekitApi.scene.set(
    { id: frameBoxId, plug: 'Transform', property: 'scale' },
    scale
  );
  threekitApi.scene.set(
    { id: frameBoxId, plug: 'Transform', property: 'translation' },
    { x: 0, y: scale.y / 2, z: 0 }
  );
  threekitApi.player.evaluateSceneGraph();
  threekitApi.camera.frameBoundingSphere([frameBoxId]);
  threekitApi.scene.set(
    { id: frameBoxId, plug: 'Transform', property: 'scale' },
    { x: 0, y: 0, z: 0 }
  );
}

export const getInstrumentDecalMetadata = async (
  threekitApi,
  instrumentData
) => {
  const { modelId } = instrumentData;
  const instanceId = await getAssetInstance(threekitApi, {
    id: modelId,
    plug: 'Null',
    property: 'asset',
  });
  const decalMeshId = await getInstrumentDecalMeshId(threekitApi, instanceId);
  const decalBB = await getNodeBoundingBox(threekitApi, decalMeshId);
  const { diffuseMap } = await getInstrumentDecalTextureId(
    threekitApi,
    decalMeshId
  );
  const textureProperty = threekitApi.scene.get({ id: diffuseMap });

  const [canvasProperty, labelProperty] = ['Canvas', 'CanvasText'].map((name) =>
    textureProperty.plugs.Image.find((properties) => properties.name === name)
  );
  const labelFontSize = getCanvasFontSize(
    {
      width: canvasProperty.width * labelProperty.windowRelativeWidth,
      height: canvasProperty.height * labelProperty.windowRelativeHeight,
    },
    labelProperty
  );
  const PtToPxRatio = getPtToPxRatio(decalBB, canvasProperty);
  return { fontSize: labelFontSize / PtToPxRatio };
};

const initialInstrumentMaterial = async (
  threekitApi,
  instanceId,
  instrumentData
) => {
  const instrumentId = threekitApi.scene.findNode({
    from: instanceId,
    name: 'Instrument',
  });
  const instrumentAssetId = await autoRetry(getAssetInstance, [
    threekitApi,
    { id: instrumentId, plug: 'Null', property: 'asset' },
  ]);
  const instruemntPartsAssetId = getInstrumentPartsAssetId(
    threekitApi,
    instrumentAssetId
  );
  applyMaterialToInsturmentParts(
    threekitApi,
    instruemntPartsAssetId,
    instrumentData
  );
};
const getInstrumentPartsAssetId = (threekitApi, instanceId) =>
  threekitApi.scene
    .filterNodes({ from: instanceId, type: 'PolyMesh' })
    .map((id) => ({
      id,
      part: threekitApi.scene
        .get({ id, property: 'name' })
        .split('_')[0]
        .toLowerCase(),
    }));
const getPtToPxRatio = (decalBB, canvasProperties) => {
  const width = decalBB.max.x - decalBB.min.x;
  const { width: canvasWidth } = canvasProperties;
  const ptToMm = 0.352778;
  const canvasPxToMm = (width * 1000) / canvasWidth;
  return ptToMm / canvasPxToMm;
};

export const getInstrumentDecalPxSize = (() => {
  // this is hard coded value from the 3D asset setup
  const decalBB = { max: { x: 0.01 }, min: { x: -0.01 } };
  const canvasProperties = { width: 1024 };
  const ratio = getPtToPxRatio(decalBB, canvasProperties);
  return (sizeInPt) => sizeInPt * ratio;
})();

const getInstrumentDecalMeshId = async (threekitApi, instanceId) => {
  const planeNullId = threekitApi.scene.findNode({
    from: { id: instanceId },
    name: DECAL_INSTRUMENT_PLANE,
  });
  const planeInstanceId = await getAssetInstance(threekitApi, {
    id: planeNullId,
    plug: 'Null',
    property: 'asset',
  });
  const planeMeshId = threekitApi.scene.findNode({
    from: { id: planeInstanceId },
    name: DECAL_INSTRUMENT_PLANE,
  });
  return planeMeshId;
};

const getInstrumentDecalTextureId = async (threekitApi, decalMeshId) => {
  const matInstanceId = await getAssetInstance(threekitApi, {
    id: decalMeshId,
    plug: 'Material',
    property: 'reference',
  });
  const diffuseInstanceId = await getAssetInstance(threekitApi, {
    id: matInstanceId,
    plug: 'Material',
    property: 'baseMap',
  });
  const opacityInstanceId = await getAssetInstance(threekitApi, {
    id: matInstanceId,
    plug: 'Material',
    property: 'opacityMap',
  });
  return { diffuseMap: diffuseInstanceId, opacityMap: opacityInstanceId };
};

export const initialTopDecalTexture = async (threekitApi, blockData) => {
  const { lidLabelFont, lidLogoPosition, lidLogoRelativeSize } = blockData;
  const blockInstanceId = await getBlockInstanceId(threekitApi);
  const decalMeshId = threekitApi.scene.findNode({
    from: { id: blockInstanceId },
    name: 'TopDecal',
  });
  const decalBB = await getNodeBoundingBox(threekitApi, decalMeshId);
  const decalAspectRatio = await getLidDecalAspectRatio(
    threekitApi,
    decalMeshId,
    decalBB
  );
  const labelSizeInPx = Math.round(
    getPtToPxRatio(decalBB, { width: 1024 }) * lidLabelFont
  );

  setBlockInstanceConfiguration(threekitApi, {
    'Lid Label Font Size': labelSizeInPx,
    'Lid Logo Relative Size': lidLogoRelativeSize,
    'Lid Logo Position': lidLogoPosition,
    'Lid Decal Aspect Ratio': decalAspectRatio,
  });
};

export const getTopDecalMetadata = async (threekitApi, blockData) => {
  const { lidLogoPosition } = blockData;
  const blockInstanceId = await getBlockInstanceId(threekitApi);
  const decalMeshId = threekitApi.scene.findNode({
    from: { id: blockInstanceId },
    name: 'TopDecal',
  });
  const textureId = await getTopDecalTextureId(threekitApi, decalMeshId);
  const textureProperty = threekitApi.scene.get({ id: textureId });
  const decalBB = await getNodeBoundingBox(threekitApi, decalMeshId);
  const [canvasProperty, logoProperty, labelProperty] = [
    'Canvas',
    'CanvasComposite',
    'CanvasText',
  ].map((name) =>
    textureProperty.plugs.Image.find((properties) => properties.name === name)
  );
  const labelFontSize = getCanvasFontSize(
    { width: labelProperty.windowWidth, height: labelProperty.windowHeight },
    labelProperty
  );
  const PtToPxRatio = getPtToPxRatio(decalBB, canvasProperty);
  const logoMetadata = {
    width: logoProperty.windowWidth / PtToPxRatio,
    height: logoProperty.windowHeight / PtToPxRatio,
    centerX: (logoProperty.xOffset - canvasProperty.width / 2) / PtToPxRatio,
    centerY: (logoProperty.yOffset - canvasProperty.height / 2) / PtToPxRatio,
    active: lidLogoPosition !== 'None',
  };
  const labelMetadata = {
    width: labelProperty.windowWidth / PtToPxRatio,
    height: labelProperty.windowHeight / PtToPxRatio,
    centerX: (labelProperty.positionX - canvasProperty.width / 2) / PtToPxRatio,
    centerY:
      (labelProperty.positionY - canvasProperty.height / 2) / PtToPxRatio,
    fontSize: labelFontSize / PtToPxRatio,
    active: lidLogoPosition !== 'Full',
  };
  return { logo: logoMetadata, label: labelMetadata };
};

const getTopDecalTextureId = async (threekitApi, decalMeshId) => {
  const matInstanceId = await getAssetInstance(threekitApi, {
    id: decalMeshId,
    plug: 'Material',
    property: 'reference',
  });
  const texInstanceId = await getAssetInstance(threekitApi, {
    id: matInstanceId,
    plug: 'Material',
    property: 'opacityMap',
  });
  return texInstanceId;
};

const getCanvasFontSize = (canvasSize, textProperty) => {
  const ctx = document.getElementById('measureCanvas').getContext('2d');
  const textArray = textProperty.text.split('<br>');
  let fontSize = Math.min(
    canvasSize.height / textArray.length,
    textProperty.fontSize
  );
  let rowIndex = 0,
    maxWidth = 0;
  ctx.font = getCanvasFont(textProperty, fontSize);
  for (let idx = 0; idx < textArray.length; ++idx) {
    const width = ctx.measureText(textArray[idx]).width;
    if (width > maxWidth) {
      rowIndex = idx;
      maxWidth = width;
    }
  }
  if (maxWidth <= canvasSize.width) return fontSize;

  let upper = fontSize,
    lower = 0;
  const text = textArray[rowIndex];
  while (upper - lower > 1) {
    const middle = Math.floor((upper + lower) / 2);
    ctx.font = getCanvasFont(textProperty, middle);
    if (ctx.measureText(text).width > canvasSize.width) upper = middle;
    else lower = middle;
  }
  return lower;
};
const getCanvasFont = (textProperty, optionalFontSize) => {
  const fontSize = optionalFontSize || textProperty.fontSize;
  const fontType = textProperty.fontCSSSpecifier;
  return `${fontSize}px ${fontType}`;
};

export const getImageAspectRatio = (imageFile) =>
  new Promise((resolve) => {
    const url = URL.createObjectURL(imageFile);
    const img = new Image();

    img.onload = function() {
      const ratio = img.width / img.height;
      URL.revokeObjectURL(img.src);
      resolve(ratio);
    };
    img.src = url;
  });
