import { LID_ANIMATION } from '../constants';
import {
  SET_INIT_STATE,
  SET_HOLDING,
  SET_SELECTED_MODEL,
  SET_BLOCK,
  ADD_INSTRUMENTS,
  DELETE_INSTRUMENTS,
  SET_INSTRUMENTS,
  SET_LID_ANIMATION,
  SET_SILHOUETTE_VISIBLE,
  SET_LOGO,
  SET_BLOCK_NAME,
} from './utils/actionType';

export const initialState = {
  selectedModel: undefined,
  block: {},
  instruments: {},
  holding: {},
  lidAnimation: { playing: false, status: LID_ANIMATION.open },
  annotationId: undefined,
  silhouetteVisible: true,
  logo: undefined,
  blockName: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INIT_STATE:
      return { ...state, ...(payload.config || {}) };
    case SET_HOLDING:
      return { ...state, holding: payload };
    case SET_SELECTED_MODEL:
      return { ...state, selectedModel: payload };
    case SET_BLOCK:
      return { ...state, block: payload };
    case ADD_INSTRUMENTS:
      const addInstruments = payload.reduce(
        (acc, instrumentData) =>
          Object.assign(acc, { [instrumentData.modelId]: instrumentData }),
        {}
      );
      return {
        ...state,
        instruments: {
          ...state.instruments,
          ...addInstruments,
        },
        selectedModel: payload[payload.length - 1].modelId,
      };
    case DELETE_INSTRUMENTS:
      const deleteInstruments = { ...state.instruments };
      const selectedModel = state.selectedModel;
      payload.forEach((modelId) => delete deleteInstruments[modelId]);
      return {
        ...state,
        instruments: deleteInstruments,
        selectedModel: deleteInstruments[selectedModel] ? selectedModel : null,
      };
    case SET_INSTRUMENTS:
      const instrumentsAfterSet = { ...state.instruments };
      payload.forEach((instrumentData) => {
        const { modelId } = instrumentData;
        instrumentsAfterSet[modelId] = {
          ...state.instruments[modelId],
          ...instrumentData,
        };
      });

      return {
        ...state,
        instruments: instrumentsAfterSet,
      };
    case SET_LID_ANIMATION:
      return { ...state, lidAnimation: payload };
    case SET_SILHOUETTE_VISIBLE:
      return { ...state, silhouetteVisible: payload };
    case SET_LOGO:
      return { ...state, logo: payload };
    case SET_BLOCK_NAME:
      return { ...state, blockName: payload };
    default:
      return state;
  }
};
